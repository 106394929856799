<template>
  <div class="ImagesContainer">
    <div class="">
      <img src="/Slide1.JPG" alt="" class="image" />
    </div>
    <div class="">
      <img src="/Slide2.JPG" alt="" class="image" />
    </div>
    <div class="">
      <img src="/Slide3.JPG" alt="" class="image" />
    </div>
    <div class="">
      <img src="/TrainingPlanUser.png" alt="" class="image" />
    </div>
    <div class="">
      <img src="/Reporting.png" alt="" class="image" />
    </div>
    <div class="">
      <img src="/Slide6.png" alt="" class="image" />
    </div>
    <div class="">
      <img src="/Slide7.png" alt="" class="image" />
    </div>
    <div class="">
      <img src="/Slide8.png" alt="" class="image" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Guide",
  components: {},
  data() {
    return {};
  },
  created() {},
};
</script>
<style></style>
<style scoped>
.image {
  width: 95%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  margin-left: 10px;
  margin-right: 10px;
  transition: transform 0.5s;
}
.ImagesContainer {
  height: calc(100vh - 120px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
